<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
            @click="searchBidCollapsed = !searchBidCollapsed"
          >
            <v-toolbar-title
              >Ödemeler
              <font-awesome-icon
                :icon="[
                  'fas',
                  searchBidCollapsed ? 'chevron-up' : 'chevron-down',
                ]"
            /></v-toolbar-title>
            <v-spacer></v-spacer>

            <div class="d-flex justify-content-end">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control"
                style="max-width: 250px"
                autocomplete="new"
                placeholder="Hızlı Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
            </div>
          </v-toolbar>
          <CCollapse :show="searchBidCollapsed">
            <CForm @submit.prevent="getPayments">
              <v-row>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    dense
                    hide-details
                    outlined
                    clearable
                    multiple
                    :items="ERPStatusOptions"
                    item-text="text"
                    item-value="value"
                    v-model="searchForm.paymentErpStatus"
                    label="ERP Aktarım Durumu"
                  />
                </v-col>

                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    multiple
                    v-model="searchForm.paymentType"
                    :items="paymentTypeOptions"
                    item-text="tr"
                    item-value="val"
                    label="Ödeme Tipi"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-select
                    outlined
                    dense
                    hide-details
                    multiple
                    v-model="searchForm.paymentTransferType"
                    :items="paymentTransferTypeOptions"
                    item-text="tr"
                    item-value="val"
                    label="Ödeme Durumu"
                  >
                  </v-select>
                </v-col>

                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="searchForm.paymentDate"
                        clearable
                        label="Ödeme Tarih Aralığı"
                        readonly
                        dense
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="searchForm.paymentDate = []"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="searchForm.paymentDate"
                      @change="datePickerMenu = false"
                      input-class="focus:outline-none"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    outlined
                    dense
                    multiple
                    hide-details
                    clearable
                    v-model="searchForm.reservationStatus"
                    :items="searchReservationFormStatusOptions"
                    item-text="tr"
                    item-value="val"
                    label="Durum"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-text-field
                    outlined
                    dense
                    @keyup="searchForm.reservationCode.toUpperCase()"
                    v-model="searchForm.reservationCode"
                    hide-details
                    :label="$t('reservation_number')"
                    type="text"
                  />
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="searchForm.vendorReservationCode"
                    hide-details
                    label="Tedarikçi Rez.no"
                    type="text"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-menu
                    v-model="datePickerReservationDateRange"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="searchForm.reservationDateTime"
                        clearable
                        readonly
                        placeholder="Rezervasyon Tarih Aralığı"
                        dense
                        label="Rezervasyon Tarih Aralığı"
                        hide-details
                        outlined
                        v-bind="attrs"
                        class="bg-white text-black"
                        v-on="on"
                        @click:clear="searchForm.reservationDateTime = []"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="searchForm.reservationDateTime"
                      @change="datePickerReservationDateRange = false"
                      input-class="focus:outline-none"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>

              <v-row>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="searchForm.driverName"
                    hide-details
                    :label="$t('driver_name')"
                    type="text"
                  />
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="searchForm.driverLastname"
                    hide-details
                    :label="$t('driver_lastname')"
                    type="text"
                  />
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="searchForm.identityId"
                    hide-details
                    label="Tc veya Pasaport No"
                    type="text"
                  />
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    clearable
                    v-model="searchForm.reservationType"
                    :items="reservationTypeOptions"
                    multiple
                    item-text="tr"
                    item-value="val"
                    label="Rezervasyon Tipi"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- <v-col md="3">
                    <v-menu
                      v-model="datePickerReturnTimeRange"
                      :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="
                            $moment(searchForm.return.date[0]).format(
                              'DD.MM.YYYY'
                            ) +
                            ' - ' +
                            $moment(searchForm.return.date[1]).format(
                              'DD.MM.YYYY'
                            )
                          "
                          clearable
                          readonly
                          placeholder="Araç İade Tarih Aralığı"
                          dense
                          label="Araç İade Tarih Aralığı"
                          hide-details
                          outlined
                          v-bind="attrs"
                          class="bg-white text-black"
                          v-on="on"
                          @click:clear="
                            searchForm.return.date = []
                          "></v-text-field>
                      </template>
                      <v-date-picker
                        range
                        v-model="searchForm.return.date"
                        @change="
                          datePickerReturnTimeRange = false
                        "></v-date-picker> </v-menu
                  ></v-col> -->
              </v-row>
              <v-row>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    v-model="searchForm.vendor"
                    :items="searchReservationFormVendorOptions"
                    :value="searchReservationFormVendorOptions.value"
                    item-text="label"
                    item-value="value"
                    label="Tedarikçi"
                    outlined
                    dense
                    multiple
                    hide-details
                    clearable
                    :menu-props="{ offsetY: true }"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    v-model="searchForm.campaignCode"
                    :items="campaignDetail"
                    :item-text="(item) => item.name"
                    item-value="id"
                    label="Kampanya"
                    outlined
                    multiple
                    dense
                    hide-details
                    clearable
                    :menu-props="{ offsetY: true }"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    outlined
                    dense
                    multiple
                    hide-details
                    clearable
                    v-model="searchForm.statusEarlyReturn"
                    :items="searchReservationFormstatusEarlyReturnOptions"
                    item-text="tr"
                    item-value="val"
                    label="Erken İade Durumu"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-autocomplete
                    outlined
                    dense
                    hide-details
                    multiple
                    clearable
                    v-model="searchForm.currency"
                    :items="currencyOptions"
                    item-text="tr"
                    item-value="val"
                    label="Rez. Para Birimi"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    v-model="searchForm.invoiceNumber"
                    label="Fatura No."
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <!-- <v-text-field
                      outlined
                      dense
                      v-model="searchForm.invoices"
                      label="Fatura Durumu">
                    </v-text-field> -->
                  <v-autocomplete
                    v-model="searchForm.invoiceTransferStatus"
                    :items="invoicesStatusOptions"
                    :item-text="(item) => item.tr"
                    item-value="val"
                    label="Fatura Durumu"
                    outlined
                    multiple
                    dense
                    hide-details
                    clearable
                    :menu-props="{ offsetY: true }"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-menu
                    v-model="datePickerInvoicesDateRange"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="searchForm.invoiceDate"
                        clearable
                        readonly
                        placeholder="Fatura Tarihi Aralığı"
                        dense
                        label="Fatura Tarihi Aralığı"
                        hide-details
                        outlined
                        v-bind="attrs"
                        class="bg-white text-black"
                        v-on="on"
                        @click:clear="searchForm.invoiceDate = []"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="searchForm.invoiceDate"
                      @change="datePickerInvoicesDateRange = false"
                      input-class="focus:outline-none"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col class="mt-1 mt-md-0" md="3" cols="6">
                  <v-menu
                    v-model="datePickerCancelledDateRange"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="searchForm.cancelledDate"
                        clearable
                        readonly
                        placeholder="Tarih Aralığı"
                        dense
                        label="İptal Tarih Aralığı"
                        hide-details
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="searchForm.cancelledDate = []"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      range
                      v-model="searchForm.cancelledDate"
                      @change="datePickerCancelledDateRange = false"
                      input-class="focus:outline-none"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
              <v-row>
                <v-col class="mt-1 mt-md-0" md="4" cols="6">
                  <v-textarea
                    outlined
                    dense
                    hide-details
                    clearable
                    auto-grow
                    v-model="searchForm.orderId"
                    :label="$t('order_number')"
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn type="submit" block color="primary">
                    <strong> ARA </strong>
                    <v-icon right dark> mdi-magnify </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </CForm>
          </CCollapse>

          <CCardBody class="p-0" v-if="showDataGrid">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowClassRules="rowClassRules"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
              :getContextMenuItems="getContextMenuItems"
            ></ag-grid-vue>
          </CCardBody>
          <!--(params) => clickOpenModalButton(`old`, params)-->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import { RoleProvider } from "../../provider";
import moment from "moment";
import Func from "../../func";

// ? utils
import { StatusToText, ERPStatusToText } from "../../utils/";
Vue.use(VueSweetalert2);

export default {
  name: "Payments",
  components: {
    AgGridVue,
    RoleProvider,
  },
  data() {
    return {
      rowClassRules: null,
      datePickerMenu: false,
      searchBidCollapsed: true,
      showDataGrid: false,
      searchFilterInput: null,
      datePickerReservationDateRange: false,
      datePickerDeliveryTimeRange: false,
      datePickerReturnTimeRange: false,
      datePickerInvoicesDateRange: false,
      datePickerCancelledDateRange: false,
      searchReservationFormVendorOptions: [],
      campaignDetail: [],
      //      enum: ["normal", "normalWithExtras", "fullCredit", "payOnArrival", "partialPayment"],

      reservationTypeOptions: [
        {
          val: "normal",
          tr: "Normal",
        },
        {
          val: "normalWithExtras",
          tr: "Normal ve Ekstra",
        },
        {
          val: "fullCredit",
          tr: "Full Credit",
        },
        {
          val: "payOnArrival",
          tr: "Teslimde ödeme",
        },
        {
          val: "partialPayment",
          tr: "Kısmi Ödeme",
        },
      ],

      searchReservationFormstatusEarlyReturnOptions: [
        {
          val: "pending",
          tr: "Bekliyor",
        },
        {
          val: "approved",
          tr: "Onaylandı",
        },
        {
          val: "rejected",
          tr: "Reddedildi",
        },
      ],
      searchReservationFormStatusOptions: [
        {
          val: "approved",
          tr: this.$t("future_reservations"),
        },
        {
          val: "active",
          tr: this.$t("ongoing_reservations"),
        },
        {
          val: "completed",
          tr: this.$t("past_reservations"),
        },
        {
          val: "cancelled",
          tr: this.$t("canceled_reservations"),
        },
        {
          val: "billed",
          tr: this.$t("invoiced_reservations"),
        },
        {
          val: "notbilled",
          tr: this.$t("not_invoiced_reservations"),
        },
        {
          val: "canceledbill",
          tr: this.$t("invoiced_cancel_reservations"),
        },
        {
          val: "paymentError",
          tr: this.$t("paymentError"),
        },
        {
          val: "noShowCancelled",
          tr: this.$t("noShowCancelled"),
        },
      ],

      modificationsUserTypeOptions: [
        {
          val: "agent",
          tr: "Admin",
        },
        {
          val: "user",
          tr: "User",
        },
        {
          val: "partner",
          tr: "Partner",
        },
      ],

      currencyOptions: [
        {
          val: "TRY",
          tr: "TRY",
        },
        {
          val: "EUR",
          tr: "EURO",
        },
        {
          val: "USD",
          tr: "USD",
        },
      ],
      paymentTypeOptions: [
        {
          val: "3dPos",
          tr: "Sanal Pos",
        },
        {
          val: "smsPos",
          tr: "SMS İle Ödeme",
        },
        {
          val: "debit",
          tr: "Cari Hesap",
        },
      ],
      paymentTransferTypeOptions: [
        {
          val: "payment",
          tr: "Tahsilat",
        },
        {
          val: "refund",
          tr: "İade",
        },
      ],
      permissionOptions: [
        {
          val: "sms",
          tr: "SMS",
        },
        {
          val: "phone",
          tr: "Telefon",
        },
        {
          val: "email",
          tr: "Email",
        },
      ],
      invoicesStatusOptions: [
        {
          val: "error",
          tr: "Hata",
        },
        {
          val: "pending",
          tr: "Bekliyor",
        },
        {
          val: "ok",
          tr: "Tamamlandı",
        },
      ],
      searchForm: {
        reservationCode: "",
        reservationDateTime: [],
        vendor: [],
        statusEarlyReturn: [],
        cancelledDate: [],
        reservationStatus: [],
        driverName: "",
        driverLastname: "",
        identityId: "",
        vendorReservationCode: "",
        campaignCode: [],
        currency: [],
        invoiceTransferStatus: [],
        invoiceDate: [],
        invoiceNumber: "",
        orderId: "",
        paymentType: [],
        paymentTransferType: [],
        paymentErpStatus: [],
        reservationType: [],
        paymentDate: [],
        partner: [],
      },
      reservationStatusOptions: StatusToText(null, "list"),
      ERPStatusOptions: ERPStatusToText(null, "list"),
      columnDefs: [
        {
          field: "payments.erpStatus",
          headerName: "ERP Durumu",
          cellRenderer: (params) => {
            return params.value == "ok"
              ? "Aktarıldı"
              : params.value == "pending"
              ? "Bekliyor"
              : params.value == "error"
              ? "Hata"
              : "";
          },
          width: 150,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          field: "status",
          headerName: "Rez. Durumu",
          enableRowGroup: true,
          cellRenderer: (params) => {
            var result = "";
            var reservationStatus = params.value;
            if (reservationStatus == "approved") {
              result = this.$t("approved");
            } else if (reservationStatus == "pending") {
              result = this.$t("pending_t");
            } else if (reservationStatus == "active") {
              result = this.$t("active");
            } else if (reservationStatus == "completed") {
              result = this.$t("completed");
            } else if (reservationStatus == "cancelled") {
              result = this.$t("cancelled");
            } else if (reservationStatus == "billed") {
              result = this.$t("billed");
            } else if (reservationStatus == "notbilled") {
              result = this.$t("notbilled");
            } else if (reservationStatus == "canceledbill") {
              result = this.$t("canceledbill");
            } else if (reservationStatus == "earlyReturnRequest") {
              result = this.$t("earlyReturnRequest");
            } else if (
              reservationStatus == "earlyReturnFinancialConfirmation"
            ) {
              result = this.$t("earlyReturnRequestWaitConfirm");
            } else if (reservationStatus == "confirmedEarlyReturn") {
              result = this.$t("confirmEarlReturn");
            } else if (reservationStatus == "paymentError") {
              result = this.$t("paymentError");
            } else if (reservationStatus == "noShowCancelled") {
              result = this.$t("noShowCancelled");
            } else {
              result = this.$t("all");
            }

            return result;
          },
          width: 150,
          hide:
            typeof this.$router.history.current.params.reservationStatus !==
            "undefined"
              ? true
              : false,
        },
        {
          field: "orderId",
          headerName: this.$t("order_number"),
          width: 220,
        },

        {
          field: "reservationCode",
          headerName: "Rez. No",
          width: 100,
        },
        {
          field: "payments.paymentType",
          headerName: "Ödeme Tipi",
          cellRenderer: (params) => {
            return params.value == "payment"
              ? "Ödeme"
              : params.value == "refund"
              ? "İade"
              : "";
          },
          width: 120,
        },
        {
          field: "payments.created",
          cellRenderer: (params) => {
            return params.value
              ? moment(params.value).format("DD.MM.YYYY")
              : "";
          },
          headerName: this.$t("payment_date"),
          width: 140,
        },
        {
          field: "reservationType",
          hide: true,
          headerName: this.$t("type"),
          cellRenderer: (params) => {
            return this.$t(params.value);
          },
          width: 100,
        },

        {
          field: "driverInfo.name",
          headerName: this.$t("name"),
          width: 110,
        },
        {
          field: "driverInfo.lastname",
          headerName: this.$t("lastname"),
          width: 110,
        },

        {
          field: "driverInfo.identity",
          headerName: "TCKN / Pasaport",
          width: 150,
          cellRenderer: (params) => {
            const value = params?.value;
            if (value.certificateType === "tc" && value.value)
              return `<b>TC:</b> ${value.value}`;
            else if (value.certificateType === "passport" && value.value)
              return `<b>P:</b> ${value.value}`;
            else return "Girilmemiş";
          },
        },
        {
          field: "payments.totalAmount",
          type: "rightAligned",
          cellStyle: (params) => {
            return params.value > 0
              ? { color: "white", "background-color": "green" }
              : { color: "white", "background-color": "red" };
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: "TRY",
            });
          },
          headerName: this.$t("amount_paid"),
          width: 140,
        },
        {
          field: "paymentType",
          valueGetter: (params) => {
            let type = params?.data?.paymentType
              ? this.$store.state.ceviri[params.data.paymentType]
              : "";
            return type;
          },
          headerName: this.$t("payment_type"),
          width: 110,
        },

        {
          field: "prices.dailyRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.dailyRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("daily_rental_fee"),
          width: 110,
        },
        {
          field: "searchPrice.totalDays",
          cellRenderer: (params) => {
            return params.value;
          },
          type: "rightAligned",
          headerName: this.$t("rental_days"),
          width: 70,
        },
        {
          field: "prices.totalRentalPrice",
          valueGetter: (params) => {
            return params.data.prices?.totalRentalPrice[
              this.$store.state.currency
            ];
          },
          cellRenderer: (params) => {
            if (!params.value) return "";
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_payment_price"),
          width: 110,
        },
        {
          field: "selectedExtras",
          valueGetter: (params) => {
            return (
              params.data?.prices?.totalExtraPrice[
                this.$store.state.currency
              ] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
          type: "rightAligned",
          headerName: this.$t("total_extras"),
          width: 110,
        },

        {
          field: "bankTransactions",
          headerName: "Taksit",
          width: 110,
          cellRenderer: (params) => {
            return (
              params.value.find((item) => item.type == "paymentSuccess")?.data
                ?.InstallmentCount || ""
            );
          },
        },
        {
          field: "posModule",
          type: "rightAligned",
          cellRenderer: (params) => {
            return params.value;
          },
          headerName: "Pos",
          width: 140,
          hide: true,
        },

        {
          field: "rentalCurrencyPrice.currencyCode",
          cellRenderer: (params) => {
            const currencyCode = this.selectCurrencyCode(params);
            return currencyCode;
          },
          type: "rightAligned",
          headerName: "Para Birimi",
          width: 110,
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("DD.MM.YYYY");
          },
          valueGetter: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("YYYY-MM-DD");
          },
          headerName: "Rez. Tarihi",
          width: 120,
          filter: "agDateColumnFilter",
        },
        {
          field: "reservationDateTime",
          cellRenderer: (params) => {
            return moment(params.data.reservationDateTime)
              .local()
              .format("HH:mm");
          },
          valueGetter: (params) => {
            return (
              moment(params.data.reservationDateTime).local().format("HH:mm") ||
              ""
            );
          },
          headerName: "Rez. Saati",
          width: 110,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("DD.MM.YYYY");
              }
            }
          },
          headerName: this.$t("cancel_date"),
          width: 140,
        },
        {
          field: "audits",
          valueGetter: (params) => {
            for (let i = 0; i < params.data.audits?.length; i++) {
              if (params.data.audits[i].type == "STATUS_CANCELLED") {
                return moment(params.data.audits[0].date)
                  .local()
                  .format("HH:mm");
              }
            }
          },
          headerName: this.$t("cancel_time"),
          width: 140,
        },
        {
          field: "carDeliveryReturn.delivery.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("purchase_date"),
          width: 140,
          filter: "agDateColumnFilter",
        },
        {
          field: "carDeliveryReturn.return.datetime",
          type: "rightAligned",
          cellRenderer: (params) => {
            return moment(params.value).format("DD.MM.YYYY - HH:mm");
          },
          headerName: this.$t("returned_date"),
          width: 140,
          filter: "agDateColumnFilter",
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData",
          valueGetter: (params) => {
            return params.data.searchPrice.campaignData != null
              ? this.$t("yes")
              : this.$t("not");
          },
          headerName: this.$t("with_a_campaign"),
          width: 140,
          hide: true,
        },
        {
          enableRowGroup: true,
          field: "searchPrice.campaignData.info.name.tr",
          headerName: this.$t("campaign_name"),
          valueGetter: (params) => {
            const data = params.data.searchPrice;
            if (data?.campaignData?.info?.name?.tr) {
              return data.campaignData.info.name.tr;
            } else if (data?.campaignData?.info?.name) {
              return data.campaignData.info.name;
            } else {
              return "Bilinmiyor";
            }
          },
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountRate",
          headerName: this.$t("campaign_discount_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.discountAmount",
          headerName: this.$t("campaign_discount_price"),
          width: 140,
          hide: true,
        },
        {
          field: "searchPrice.campaignData.vendorCommission",
          headerName: this.$t("campaign_vendor_commision_rate"),
          width: 140,
          hide: true,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("delivery_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.return.name",
          headerName: this.$t("return_office"),
          width: 250,
        },
        {
          field: "carDeliveryReturn.delivery.name",
          headerName: this.$t("differen_returns"),
          width: 110,
          valueGetter: (params) => {
            return params.data.carDeliveryReturn.return.name !=
              params.data.carDeliveryReturn.delivery.name
              ? this.$t("yes")
              : this.$t("not");
          },
          hide: true,
        },
        {
          field: "prices.dropPrice",
          headerName: this.$t("different_return_amount"),
          type: "rightAligned",
          width: 140,
          valueGetter: (params) => {
            return (
              params.data?.prices?.dropPrice[this.$store.state.currency] || 0
            );
          },
          cellRenderer: (params) => {
            return Func.toMoneyFormat(params?.value).toLocaleString("tr-TR", {
              style: "currency",
              currency: this.$store.state.currency,
            });
          },
        },
        {
          field: "searchPrice.car.model",
          headerName: "Model",
          width: 100,
        },
        {
          field: "searchPrice.car.brand",
          headerName: this.$t("brand"),
          width: 100,
        },

        {
          field: "driverInfo.email",
          headerName: this.$t("driver_email"),
          width: 200,
        },
        {
          field: "driverInfo.phone",
          headerName: this.$t("driver_phone"),
          width: 130,
          hide: true,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
            text: "asdas",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      rowSelection: "multiple",
      rowData: [],
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.searchForm.paymentDate
        ? this.$moment(this.searchForm.paymentDate).format("DD/MM/YYYY")
        : "";
    },
  },
  created() {
    if (this.$route.query.status) {
      this.queryToSearchForm();
    }

    this.getCampaignDetail();
    this.getVendors();
  },
  mounted() {
    this.rowClassRules = {
      // apply green to 2008
      "rag-green-outer": function (params) {
        return params.data.payments.totalAmount > 0;
      },

      // apply red to 2000
      "rag-red-outer": function (params) {
        return params.data.payments.totalAmount < 0;
      },
    };
  },
  methods: {
    getContextMenuItems(params) {
      var result = [
        {
          name: "ERP Aktarım",
          subMenu: [
            {
              name: "LOGO'ya Aktar",
              action: () => {
                // ? get selected rows
                const selectedRows = this.gridApi.getSelectedRows();
                // ? get selected rows id
                const selectedRowsId = selectedRows.map(
                  (item) => item.payments._id
                );
                // ? send to logo
                this.sendToLERP(selectedRowsId);
              },
            },
            // {
            //   name: 'Aktarılmayacak olarak işaretle',
            //   action: () => {
            //     console.log('UK was pressed');
            //   },
            // },
          ],
        },
        "separator",
        "copy",
        "separator",
        "chartRange",
        "export",
      ];
      return result;
    },
    async sendToLERP(params) {
      this.$store.state.isLoading = true;
      let response = axios
        .post(process.env.VUE_APP_API_URL + "admin/erp/payments/send", {
          paymentIds: params,
        })
        .then((response) => {
          Vue.swal({
            icon: "success",
            title: "Başarılı",
            html: "ERP Aktarımları başarıyla tamamlandı. Ekran verileri güncellendi.",
            confirmButtonText: "Tamam",
          });
        })
        .catch((error) => {
          Vue.swal({
            icon: "error",
            title: "Hata",
            html: "Aktarım sırasında ERP sunucunuzda bir hata oluştu. Ekrandaki hatalı kayıtları rezervasyon detay sayfalarından inceleyebilirsiniz.",
            confirmButtonText: "Tamam",
          });
        })
        .finally(() => {
          this.getPayments();
          this.$store.state.isLoading = false;
        });
    },
    onRowDoubleClicked(params) {
      let routeData = this.$router.resolve({
        name: "Rezervasyon Detayı",
        params: {
          reservationCode: params.node.data.reservationCode,
        },
      });
      window.open(routeData.href, "_blank");
    },

    queryToSearchForm() {
      if (this.$route.query.status) {
        this.searchForm.paymentErpStatus.push(this.$route.query.status);
      }
      this.getPayments();
    },

    async getCampaignDetail() {
      let response = await axios.get(
        process.env.VUE_APP_API_URL + "campaign/all"
      );
      const result = response.data.map((item) => ({
        id: item.id,
        name: item.info.name.tr,
      }));
      this.campaignDetail = result;
    },
    getVendors() {
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor?status=true")
        .then((response) => {
          var array = response.data.vendorList;
          for (let index = 0; index < array.length; index++) {
            this.searchReservationFormVendorOptions.push({
              label: array[index].brand,
              value: array[index].brandSlug,
            });
          }
        });
    },

    async getPayments() {
      this.$Progress.start();
      try {
        const { data } = await axios.post(
          process.env.VUE_APP_API_URL + "admin/payments",
          this.searchForm
        );
        this.rowData = data;
      } catch (error) {
        Vue.swal({
          icon: "error",
          title: "Hata",
          html: this.$t(error.response.data.message),
          confirmButtonText: "Tamam",
        });
      } finally {
        this.$Progress.finish();
        this.searchBidCollapsed = false;
        this.showDataGrid = true;
      }
    },
    checkRentalCurrency(params) {
      const rentalCurrencyData = params?.data?.rentalCurrencyPrice;
      let check =
        rentalCurrencyData !== null &&
        rentalCurrencyData &&
        rentalCurrencyData.currencyCode !== ""
          ? true
          : false;
      return check;
    },

    selectCurrencyCode(params) {
      const currencycode = this.checkRentalCurrency(params)
        ? params?.data?.rentalCurrencyPrice?.currencyCode
        : params?.data?.currency?.currencyCode;
      return currencycode;
    },

    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>
<style>
.rag-green-outer {
  background-color: green;
  color: white;
}
</style>
